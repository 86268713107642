export enum ERROR_CODES {
  ERROR = 'ERROR', // 알 수 없는 오류
  SERVER_ERROR = 'SERVER_ERROR', // 서버 내부 오류
  NO_PERMISSION = 'NO_PERMISSION', // 권한이 없음
  NOT_FOUND = 'NOT_FOUND', // 요청한 아이템을 찾지 못함
  NOT_EXIST_USER = 'NOT_EXIST_USER', // 사용자가 존재하지 않음 (카카오 계정 삭제 시)
  UNAUTHORIZED = 'UNAUTHORIZED', // 로그인 필요
  TOKEN_EXPIRED = 'TOKEN_EXPIRED', // getNewAccessToken query(refresh token 만료), unauthenticate mutation에서 발생할 수 있다.
  INVALID_TOKEN = 'INVALID_TOKEN', // 유효하지 않은 토큰
  VALIDATION_ERROR = 'VALIDATION_ERROR', // input 값들 중 조건과 맞지 않을 때 발생. (예: 길이가 100자인데 초과했다.)
  OAUTH_PROVIDER_ERROR = 'OAUTH_PROVIDER_ERROR', // 카카오, 네이버 로그인 에러
  ACCESS_DENIED = 'ACCESS_DENIED', // 자동 로그인 실패시
  SERVICE_AND_TERMS_DENIED = 'SERVICE_AND_TERMS_DENIED', // 서비스 이용약관 업데이트가 적용되지 않음
}
export const REQUIRED_ERROR = '꼭 입력해야 하는 정보입니다.'

// encodeStateQuery 처리 시에 치환 문자 포함되는 경우에 대한 에러
export const ENCODE_STATE_QUERY_ERROR =
  'redirect path should not include encode symbols'
export const REFRESH_TOKEN_NOT_FOUND_ERROR = 'refreshToken is not exist'

export type ErrorCode = keyof typeof ERROR_CODES
